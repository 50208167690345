import { ProductDetailSelection, ProductSelection } from 'features/commerce/product-details/types';
import { Order, OrderItem } from 'features/commerce/shopping-cart/types';
import addRocEventHandler from 'shared/util/events/rocEventUtils';
import { CommercePageType, TrackSaleItemArgs } from './tracking-commerce';
import HawkSearch from '../../hawksearch/tracking';

/**
 * Helper function for converting order model to the list of items in format expected by Google Analytics
 * @param order
 */
function convertOrderToHawkSearchItems(order: Order): Array<TrackSaleItemArgs> {
	// helper function for resolving item's price
	const resolveItemPrice = (item: OrderItem): number => {
		if (item.unitPrice) {
			if (item.unitPrice.salePrice) {
				return item.unitPrice.salePrice.rawValue;
			}

			if (item.unitPrice.basePrice) {
				return item.unitPrice.basePrice.rawValue;
			}
		}

		return 0;
	};

	return order.recipients.reduce(
		(items, recipient) => [
			...items,
			...recipient.items.map((item) => {
				return {
					uniqueId: item.product.productGroupId ?? item.product.id,
					itemPrice: resolveItemPrice(item),
					quantity: item.quantity,
				};
			}),
		],
		[],
	);
}

/**
 * Represents attributes and values used for adding in hawksearch context
 */
export interface HawksearchAttributes {
	name: string;
	value?: string;
}

/**
 * Helper function to add the associated group product Id of a simple product, if applicable.
 * @param uniqueId - the associated unique group product Id of a simple product, if applicable.
 */
function addProductToContext(uniqueId: string) {
	HawkSearch.Context.add('unique_id', uniqueId.toString());
}

/**
 * Helper function to add multiple associated group product Ids of a simple product, if applicable.
 * @param uniqueId - the associated unique group product Id of a simple product, if applicable.
 */
function addMultipleProductsToContext(uniqueId: string) {
	HawkSearch.Context.push('unique_id', uniqueId.toString());
}

/**
 * Helper function to add attribute value to hawk context
 * @param selections - The list of selectable attributes and their values for the product which are relevant for product recommendations.
 */
function addProductAttributesToContext(hawkAttributes: HawksearchAttributes[]) {
	// add attribute to context if the tracking key is present
	hawkAttributes?.map((attribute) => {
		if (attribute.value) {
			HawkSearch.Context.add(attribute.name, attribute.value);
		} else {
			HawkSearch.Context.remove(attribute.name);
		}
	});
}

/**
 * Helper function to add multiple attribute values to hawk context separated by ','
 * @param selections - The list of selectable attributes and their values for the product which are relevant for product recommendations.
 */
function addMultipleProductAttributesToContext(hawkAttributes: HawksearchAttributes[]) {
	// add attribute to context if the tracking key is present
	hawkAttributes?.map((attribute) => {
		if (attribute.value) {
			HawkSearch.Context.push(attribute.name, attribute.value);
		} else {
			HawkSearch.Context.remove(attribute.name);
		}
	});
}

/**
 * Converts roc product selections to hawk context attribute fields
 * @param selections - The list of selectable attributes and their values for the product which are relevant for product recommendations.
 */
export function selectionsToHawkAttributes(
	selections: ProductDetailSelection[] | undefined | null,
): HawksearchAttributes[] {
	const hawkSearchAttributes: HawksearchAttributes[] = selections?.length
		? selections.map((selection: ProductDetailSelection) => {
				return {
					name: selection.field,
					value: selection.values?.find((s) => s.checked === true)?.label ?? undefined,
				};
		  })
		: [];

	return hawkSearchAttributes;
}

/**
 * Converts roc product selections to hawk context attribute fields
 */
export function convertProductSelectionToHawksearchAttributes(
	selections: ProductSelection[] | null | undefined,
): HawksearchAttributes[] {
	const hawkSearchAttributes: HawksearchAttributes[] = selections?.length
		? selections.map((selection: ProductSelection) => {
				return {
					name: selection.field,
					value: selection.label.toString() ?? undefined,
				};
		  })
		: [];

	return hawkSearchAttributes;
}

export default function setup() {
	addRocEventHandler('cart-page-viewed', () => {
		HawkSearch.Tracking.track('pageload', {
			pageType: CommercePageType.cart,
			uniqueId: '',
		});
	});

	addRocEventHandler('product-viewed', ({ productId, productGroupId, selections }) => {
		if (productGroupId != null) {
			const hawkAttributes = selectionsToHawkAttributes(selections);
			addProductAttributesToContext(hawkAttributes);
		}
		addProductToContext(productGroupId ?? productId);

		HawkSearch.Tracking.track('pageload', {
			pageType: CommercePageType.item,
			uniqueId: productGroupId ?? productId,
		});
	});

	addRocEventHandler('product-rated', ({ productId, rating }) => {
		HawkSearch.Tracking.trackCommerce('rate', {
			uniqueId: productId,
			value: rating,
		});
	});

	addRocEventHandler('order-placed', ({ order }) => {
		order.recipients.map((recipient) => {
			recipient.items.map((item) => {
				if (item.product.productGroupId != null) {
					const hawkAttributes = convertProductSelectionToHawksearchAttributes(item.selections);
					addMultipleProductAttributesToContext(hawkAttributes);
				}
			});
		});

		HawkSearch.Tracking.trackCommerce('sale', {
			orderNo: order.orderNo || order.orderCode,
			currency: order.snapshotCurrencyISO,
			itemList: convertOrderToHawkSearchItems(order),
			subTotal: order.subtotal.basePrice.rawValue,
			tax: order.tax?.basePrice.rawValue,
			total: order.total.basePrice.rawValue,
		});

		HawkSearch.Tracking.track('pageload', { pageType: CommercePageType.order, uniqueId: '' });
	});

	addRocEventHandler('added-to-cart', ({ items }) => {
		items.map((item) => {
			if (item.productGroupId) {
				const hawkAttributes = convertProductSelectionToHawksearchAttributes(item.selections);
				addProductAttributesToContext(hawkAttributes);
			}
			addProductToContext(item.productGroupId ?? item.id);
		});

		HawkSearch.Tracking.trackCommerce('add2cartmultiple', {
			itemsList: items.map((item) => {
				return {
					currency: item.currency,
					price: item.price,
					quantity: item.adjustedQuantity,
					uniqueId: item.productGroupId ?? item.id,
				};
			}),
		});
	});

	addRocEventHandler('added-to-cart-multiple', ({ items }) => {
		items.map((item) => {
			if (item.productGroupId) {
				const hawkAttributes = convertProductSelectionToHawksearchAttributes(item.selections);
				addMultipleProductAttributesToContext(hawkAttributes);
			}
			addMultipleProductsToContext(item.productGroupId ?? item.id);
		});

		HawkSearch.Tracking.trackCommerce('add2cartmultiple', {
			itemsList: items.map((item) => {
				return {
					currency: item.currency,
					price: item.price,
					quantity: item.adjustedQuantity,
					uniqueId: item.productGroupId ?? item.id,
				};
			}),
		});
	});

	addRocEventHandler('product-clicked', ({ productId, productGroupId, url }) => {
		if (!window.HawkSearchTrackingId) {
			// do not report product clicks unless there is tracking id from hawk
			return;
		}

		HawkSearch.Tracking.track('click', {
			uniqueId: productGroupId ?? productId,
			url: url,
			trackingId: window.HawkSearchTrackingId,
		});
	});

	addRocEventHandler('product-selection-updated', ({ product }) => {
		const hawkAttributes = selectionsToHawkAttributes(product.productDetails?.selections);
		addProductAttributesToContext(hawkAttributes);
		addProductToContext(product.productDetails?.summary?.productGroupId ?? product.productId);
	});
}
